const zs = {
    title:{
        '1':'记忆力下降，会很快变成老年痴呆吗？',
        '2':'脖子僵硬、酸胀，是不是得了颈椎病？',
        '3':"男性脱发怎么办？",
        '4':"种植牙松动、脱落了怎么办？"
},
    detail:{
        "1":'<p><span style="font-family:microsoft yahei;"><span style="font-size:16px;">痴呆会导致记忆力下降，首先要区分到底是真性痴呆还是假性痴呆。<strong><span style="color:rgb(255, 153, 0);">真正痴呆</span></strong>表现出的记忆力下降，特点是记性差，就算别人反复提醒，仍然记不起来。<span style="color:rgb(255, 153, 0);"><strong>假性痴呆</strong></span>导致的记忆力下降就是容易忘事儿，但是经过别人提醒，或者自己仔细回忆一下，就又能记起来了。这位王先生记忆力下降很厉害，但是仔细回想之后还是能够记起来的，所以并不是真正意义上老年痴呆的先兆。</span></span><br><br> <span style="font-family:microsoft yahei;"><span style="font-size:16px;">记忆力下降不一定会变成老年痴呆。一般找到导致王先生记忆力下降的原因后，比如心情不好，睡眠不足等，避免这些诱因，记忆力就能好转；但是，如果一直找不到原因，那么王先生记忆力下降的情况会越来越重，日后很有可能发展成老年痴呆。</span></span><br><br> <span style="font-size:16px;"><span style="font-family:microsoft yahei;"></p>',
        "2":'<p>患者这种情况不属于颈椎病。原因有两点：第一，随着年龄的增加，椎间盘会出现老化退变，这是自然的生理过程，就像是年纪大了会长皱纹一样。单纯第七节颈椎轻微膨出，这是椎间盘退变的早期表现，就像是脸上的细纹，无需大惊小怪。<br><br>第二，颈椎病之所以称为“病”，意思是患者除了核磁等影像学检查有问题之外，还要有颈椎病变引起的症状，单靠影像学的检查结果就诊断颈椎病，证据是不充分的。而单纯的颈椎膨出，不会导致患者的脖子和肩膀出现肌肉僵硬、酸胀。这应该是他平时总是长时间低头、坐姿不好等问题导致的肌肉劳损，所以需要锻炼肌肉，而不是做手术。</p>',
    '3':'<p>头发实际上每天都有脱有生，生生不息。如果每天脱发在100根以内，基本上可以说是正常的新陈代谢状态下的生理性脱发。每天头发脱落数量超过100根的时候必须当心了。<br><br> 当自己感觉头发脱落较多的时候，最好将自己脱落的头发从枕头上、梳子上以及洗头发时收集在一起，如果平均每天脱发超过100根，并持续2-3个月以上，或与以前比较脱发明显增加时，就有必要求助于正规医疗机构的皮肤科医生予以诊治。<br><br> <strong>提示：</strong>一旦您在照镜子时或同事发现您头发变稀时，实际您的头发已经少了30%左右。所以当您发现自己头发稀少时，建议最好到脱发专科就诊，让专业人士给予专业指导。<br><br> 还可以自己做拉发实验，5天内不洗发，用拇指和食指拉起一束毛发，大约五六十根，然后用轻力顺毛干向发梢方向滑动。计数拔下的毛发数，超过6根以上为阳性，表明有活动性脱发；少于6根为阴性，可属于正常生理性脱发。雄激素性秃发患者往往为阴性，而活动性斑秃、急性或慢性休止期脱发、急性生长期脱发者的活动期可为阳性。<!-- <a class="more-btn" href="">点击了解...</a> !--> </p>',
    '4':'<p>根据1995年中国专家在珠海讨论提出的种植牙成功标准之一：“种植牙在行使义齿功能条件下无任何临床动度”，也就是患者在咬东西的时候种植牙不松动，所以这个患者应该是种植牙失败了。<br><br> 当前种植牙的10年成功率能够达到90%以上，5年成功率为95%以上，而且种植牙从上世纪70年代末开展到现在，最长的患者已经使用了40多年。所以实际上，可以把种植牙当成一种永久的修复体，如果患者能够健康地使用，是可能用一辈子的。<br><br> 患者种植牙松动原因，考虑是种植牙的周围发生了炎症，出现了骨吸收，也就是下方的牙槽骨萎缩了，没了根基，种植牙就不能很好地固定，就会松动脱落。引起炎症和骨吸收的因素主要有以下几点：牙周病；牙槽骨有炎症；牙龈不健康；种植牙的位置或者方向不好；吸烟；高血糖。<br><br> 对于患者来说，具体是哪些原因引起的种植牙脱落，还需要进一步检查才能确认。糖尿病虽然是种植牙失败的危险因素之一，但如果血糖控制正常是不影响种植牙治疗的。                                   <!-- <a class="more-btn" href="">点击了解...</a> !--></p>'
    }

}
export default zs;