<template>

  <div class="box">
      <h3 class="title">{{title}}</h3>
      <article class="deteil" v-html="detail"></article>
  </div>
</template>

<script>
import mock from '../../mock/zhishi.js'
export default {
  props: ["Id"],
  data() {
    return {
     detail:mock['detail'][this.Id],
     title:mock['title'][this.Id]
    }
  }
}
</script>

<style>
.box{
  padding: 100px 50px;
}
.box .title{font-size:20px;color:#333;text-align: center;}
.box .deteil{font-size:17px;color:#585050;padding:15px;}
</style>
